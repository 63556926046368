<template>
    <div class="Catalog">

        <Loading v-if="!productsLoaded"></Loading>

        <template v-if="isShopAvailable && productsLoaded">
            <CatalogFilters></CatalogFilters>
            <CatalogGrid></CatalogGrid>
        </template>

        <ShopUnavailable
            v-if="!isShopAvailable"
        ></ShopUnavailable>

    </div>
</template>

<script>
import CatalogGrid from './CatalogGrid.vue';
import CatalogFilters from './CatalogFilters.vue';
import ShopUnavailable from '@/views/Webmaster/Webvorkshop/ShopUnavailable';

export default {
    name: 'Catalog',
    components: {
        CatalogGrid,
        CatalogFilters,
        ShopUnavailable,
    },
    props: [],
    data() {
        return {
            productsLoaded: false,
        };
    },
    computed: {
        isShopAvailable() {
            return this.$store.state.shop.isShopAvailable;
        },
    },
    methods: {
        setDefaultCategory() {
            const defaultCategoryId = '156';

            let hasProductsCategory = collect(this.$store.state.shop.taxons).where('id', defaultCategoryId).count() > 0;
            if (hasProductsCategory) {
                this.$store.commit('shop/set', ['catalogFilters', {byCategory: defaultCategoryId}]);
            }
        },
    },
    beforeMount() {
        this.$store.dispatch('shop/getTaxons').then(() => {
            this.setDefaultCategory();
        });
        this.$store.dispatch('shop/getProducts').then(() => {
            this.productsLoaded = true;
        });
    },
    mounted() {
        WV.components.webvorkshop_catalog = this;
    },
};
</script>

<style lang="scss">
@import '../../../../scss/utils/mixins.scss';
@import '../../../../scss/utils/variables.scss';

.Catalog{
    width: 100%;
    margin-top: 90px;
    @include break(md) {
        margin-top: 0;
    }
}

</style>
