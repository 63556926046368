<template>
    <div class="ShopUnavailable">

        <div class="ShopUnavailable__card">
            <Card>
                <h3 class="ShopUnavailable__title">
                    {{ $lng.__('lk_webmaster_webvorkshop', 'Store is temporarily unavailable') }}
                </h3>
                <p class="ShopUnavailable__text">
                    {{ $lng.__('lk_webmaster_webvorkshop', 'Please come back later. If the problem still persists, let your account manager know.') }}
                </p>
            </Card>
        </div>

        <DashboardManager></DashboardManager>

    </div>
</template>

<script>
    import DashboardManager from '@/views/Webmaster/Dashboard/DashboardManager';

    export default {
        name: 'ShopUnavailable',
        components: {
            DashboardManager,
        }
    };
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.ShopUnavailable {
    width: 100%;
    margin: 30px auto 0;
    @include break(xl) {
        width: 600px;
    }
    &__card{
        padding-bottom: 30px;
    }
    &__title{
        padding-bottom: 20px;
        font-size: 24px;
        line-height: 120%;
    }
    &__text{
        font-size: 18px;
        line-height: 130%;
    }
}
</style>
