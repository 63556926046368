<template>
    <div class="ShopProduct">

        <div class="ShopProduct__wrapper">

            <Loading v-if="!productLoaded"></Loading>

            <template v-if="productLoaded && !productHasntData">

                <div class="ShopProduct__gallery">

                    <Swiper
                        :slidesPerView="1"
                        :spaceBetween="0"
                        :loop="true"
                        :grabCursor="true"
                        :autoHeight="true"
                        :pagination="{ clickable: true }"
                        :navigation="{
                      prevEl: '.news-swiper-button .prevSlide',
                      nextEl: '.news-swiper-button .nextSlide'
                    }"
                    >
                        <SwiperSlide v-for="image in productImages">
                            <img
                                :src="image"
                                :alt="product.name"
                            >
                        </SwiperSlide>
                    </Swiper>

                </div>

                <div class="ShopProduct__content">

                    <div class="ShopProduct__back">
                        <router-link
                            class="ShopProduct__back__link"
                            :to="'/'+this.$route.params.lng+'/webmaster/shop'"
                            v-slot="{href, route, navigate}">
                            ← {{ $lng.__('lk_webmaster_webvorkshop', 'All products') }}
                        </router-link>
                    </div>

                    <div class="ShopProduct__name">
                        {{ product.name }}
                    </div>
                    <div
                        v-if="product.description"
                        class="ShopProduct__description">
                        {{ product.description }}
                    </div>


                    <div v-if="productsRelated.length > 1" class="ShopProduct__colors">
                        <div
                            v-for="relatedProduct in productsRelated"
                            class="ShopProduct__color"
                            @click="switchProductToRelated(relatedProduct)"
                            :class="{active:relatedProduct.id===product.id}"
                            :style="{backgroundColor:relatedProduct.color}">
                        </div>

                    </div>


                    <div class="ShopProduct__buy">
                        <div class="ShopProduct__price">
                            <div class="ShopProduct__price__value">
                                {{ $format.price(product.price) }}
                            </div>
                            <div class="ShopProduct__price__currency">
                                {{ $lng.__('lk_webmaster_webvorkshop', 'bonuses') }}
                            </div>
                        </div>
                        <div
                            class="ShopProduct__addToCart">
                            <button
                                v-if="!isProductAddedToCart"
                                @click="addToCart()"
                                class="wv-btn--text wv-btn--green">
                                {{ $lng.__('lk_webmaster_webvorkshop', 'Add to cart') }}
                            </button>
                            <button
                                v-if="isProductAddedToCart"
                                @click="$router.push('/'+$route.params.lng+'/webmaster/shop/checkout')"
                                class="wv-btn--text wv-btn--green-active">
                                {{ $lng.__('lk_webmaster_webvorkshop', 'Added. Go to cart') }} →
                            </button>
                        </div>
                    </div>

                </div>

            </template>
        </div>

        <template v-if="productLoaded && productHasntData">
            <div class="ShopProduct__placeholder">
                <div class="ShopProduct__placeholder__text">
                    <Card>
                        <div class="ShopProduct__back">
                            <router-link
                                class="ShopProduct__back__link"
                                :to="'/'+this.$route.params.lng+'/webmaster/shop'"
                                v-slot="{href, route, navigate}">
                                ← {{ $lng.__('lk_webmaster_webvorkshop', 'All products') }}
                            </router-link>
                        </div>
                        <p><strong>{{ $lng.__('lk_webmaster_webvorkshop', 'Details for this product will be added soon.') }}</strong></p>
                        <p>{{ $lng.__('lk_webmaster_webvorkshop', 'If you want to know about the product now - please contact your manager.') }}</p>
                    </Card>
                </div>

                <DashboardManager></DashboardManager>

            </div>

        </template>

    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import SwiperCore, {Navigation, Pagination} from 'swiper/core';
import DashboardManager from '../Dashboard/DashboardManager.vue'

SwiperCore.use([Navigation, Pagination]);

export default {
    name: 'Product',
    components: {
        Swiper,
        SwiperSlide,
        DashboardManager,
    },
    props: [],
    data() {
        return {
            productLoaded: false,
        };
    },
    computed: {
        product() {
            return this.$store.state.shop.product;
        },
        productsRelated() {
            return this.$store.state.shop.productsRelated;
        },
        productImages() {
            return this.product.images === null
                ? ['https://minio.cdnwv.com/lk.webvork/goods/placeholders/lg.png']
                : this.product.images;
        },
        productHasntData() {
            return this.product === null
                || this.product === []
                || !this.product.name;
        },
        orderProducts() {
            return this.$store.state.shop.order.products;
        },
        isProductAddedToCart() {
            return collect(this.orderProducts)
                .where('product_id', this.$route.params.id)
                .count() > 0
        },
    },
    methods: {
        addToCart() {
            this.$store.commit('shop/addProductToCart');
            this.$store.dispatch('shop/saveOrderToLocalStorage');
            WV.toast.success('Shopping cart successfully updated');
        },
        switchProductToRelated(relatedProduct) {
            this.productLoaded = false;
            this.$store.commit('shop/set', ['product', relatedProduct]);
            this.$router.push('/'+this.$route.params.lng+'/webmaster/shop/product/'+relatedProduct.id);
            this.productLoaded = true;
        },
    },
    beforeMount() {
        this.$store.dispatch('shop/getProduct', this.$route.params.id)
            .then(() => {
                this.productLoaded = true;
                if (this.product.is_inactive) {
                    this.$router.push('/'+this.$route.params.lng+'/webmaster/shop');
                }
            });
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.ShopProduct {
    margin-top: 90px;
    width: 100%;
    margin-bottom: 50px;
    @include break(md) {
        margin-top: 20px;
    }
    @include break(xl) {
        margin-top: 60px;
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        @include break(lg) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
    &__gallery {
        width: 100%;
        margin-right: 30px;
        flex: none;
        position: relative;
        height: 400px;
        margin-bottom: 30px;
        @include break(lg) {
            width: 40%;
        }
        .swiper-container{
            padding-bottom: 30px !important;
        }
        .swiper-wrapper {
            position: relative;
        }
        .swiper-slide {
            height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .swiper-pagination {
            bottom: 0;
        }
    }
    &__content {
        background: white;
        border-radius: 20px 0 0 20px;
        margin-right: -20px;
        padding: 50px;
        @include break(lg) {
            margin-right: -30px;
        }
    }
    &__back {
        padding-bottom: 45px;
        &__link {
            font-size: 14px;
            color: $color-text-muted;
            @include break(hover) {
                text-decoration: underline;
            }
        }
    }
    &__name {
        font-family: $font-primary;
        @extend .fix-montserrat;
        font-weight: 700;
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 30px;
        @include break(xl) {
            font-size: 32px;
            line-height: 40px;
        }
    }
    &__description {
        font-family: $font-primary;
        @extend .fix-montserrat;
        font-size: 16px;
        line-height: 26px;
    }
    &__colors{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 0 0 0;
    }
    &__color{
        position: relative;
        height: 35px;
        width: 35px;
        border-radius: 5px;
        cursor: pointer;
        margin-right: 10px;
        border: 1px solid #f4f4f4;
        //box-shadow: 1px 2px 2px rgb(0 0 0 / 20%);
        &.active{
            border: 1px solid $color-day-green;
            &::before{
                position: absolute;
                top: -5px;
                right:-5px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: $color-day-green;
                width: 15px;
                height: 15px;
                content: '✔';
                font-size: 10px;
                color: white;

            }
        }
    }
    &__buy {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid $color-day-line;
        @include break(sm) {
            flex-direction: row;
            align-items: flex-start;
        }
    }
    &__price {
        font-family: $font-primary;
        @extend .fix-montserrat;
        &__value {
            font-size: 40px;
            font-weight: 700;
            line-height: 40px;
        }
        &__currency {
            font-size: 16px;
            font-weight: 500;
            padding-top: 5px;
            margin-bottom: 30px;
        }
    }
    &__addToCart {
        width: 100%;
        @include break(sm) {
            width: auto;
        }
        button {
            flex: none;
            width: 100%;
            @include break(sm) {
                width: auto;
            }
        }
    }
    &__placeholder{
        margin: 0 auto;
        max-width: 600px;
        &__text{
            margin-bottom: 30px;
            p{
                font-size: 18px;
                line-height: 130%;

                &:first-of-type{
                    margin-bottom: 20px;
                }
            }
        }
    }
}

</style>
