<template>
    <div class="Order">
        <div class="Order__info">
            <div class="Order__header">
                <div class="Order__title">
                    {{ $lng.__('lk_webmaster_webvorkshop', 'Order') }} № {{ order.id }}
                </div>
                <div class="Order__status">
                    {{ $lng.__('lk_webmaster_webvorkshop', order.status) }}
                </div>
            </div>

            <div class="Order__details">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>{{ $lng.__('lk_webmaster_webvorkshop', 'Created at') }}</td>
                            <td>{{ order.created_ts }}</td>
                        </tr>
                        <tr>
                            <td>{{ $lng.__('lk_webmaster_webvorkshop', 'Recipient') }}</td>
                            <td>{{ order.contacts.first_name }} {{ order.contacts.middle_name }} {{ order.contacts.second_name }}</td>
                        </tr>
                        <tr>
                            <td>{{ $lng.__('lk_webmaster_webvorkshop', 'Phone') }}</td>
                            <td>{{ order.contacts.phone }}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{{ order.contacts.email }}</td>
                        </tr>
                        <tr v-if="order.contacts.telegram">
                            <td>Telegram</td>
                            <td>{{ order.contacts.telegram }}</td>
                        </tr>
                        <tr v-if="order.contacts.whatsapp">
                            <td>Whatsapp</td>
                            <td>{{ order.contacts.whatsapp }}</td>
                        </tr>
                        <tr v-if="order.contacts.skype">
                            <td>Skype</td>
                            <td>{{ order.contacts.skype }}</td>
                        </tr>
                        <tr>
                            <td>{{ $lng.__('lk_webmaster_webvorkshop', 'Comment') }}</td>
                            <td>{{ order.contacts.comment }}</td>
                        </tr>
                        <tr>
                            <td>{{ $lng.__('lk_webmaster_webvorkshop', 'Delivery address') }}</td>
                            <td>{{ order.address.country }}, {{ order.address.city }},
                                {{ order.address.zipcode }}, {{ order.address.street }},
                                {{ order.address.house }}, {{ order.address.apartment }}.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="Order__details">
            <OrderProduct
                v-for="product in order.products"
                :key="product.id"
                :orderProduct="product"
            />
        </div>
    </div>
</template>

<script>
import OrderProduct from '@/views/Webmaster/Webvorkshop/Orders/OrderProduct';

export default {
    name: 'Order',
    components: {OrderProduct},
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fulfilled: '',
            nocancelled: '',
            cancelled: '',
            orderTotalBonuses: '',
        };
    },
};
</script>

<style lang="scss">
@import '../../../../scss/utils/mixins.scss';
@import '../../../../scss/utils/variables.scss';

.Order{
    width: 100%;
    max-width: 800px;
    border-radius: 20px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(233, 233, 233);
    padding: 40px;
    margin: 0 auto 40px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 59px 46px -26px;
    &__info{
        width: 100%;
    }
    &__header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: -10px;
    }
    &__title{
        font-size: 20px;
        font-weight: 800;
        @extend .fix-montserrat;
    }
    &__status {
        width: fit-content;
        padding: 10px 40px;
        border-radius: 20px;
        background-color: #edf1f5;
        border: 1px solid rgb(233, 233, 233);
    }
    &__details {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 20px 0 0;
        .table{
            tbody{
                tr{
                    td{
                        border-bottom: 1px solid $color-day-line;
                        padding: 12px 10px;
                        &:first-of-type{
                            padding-left: 0;
                            font-family: $font-primary;
                        }
                        &:last-of-type{
                            font-family: $font-primary;
                            font-weight: 500;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>
