<template>
    <div class="orderProduct">
        <slot></slot>
        <a class="orderProduct__img">
            <img :src="orderProduct.image" alt="">
        </a>
        <div class="orderProduct__description">
            <div
                @click.prevent="$router.push('/'+$route.params.lng+'/webmaster/shop/product/'+orderProduct.product_id)"
                class="orderProduct__title">
                {{ orderProduct.name }}
            </div>
            <div class="orderProduct__price">
                <strong>{{ orderProduct.quantity }}</strong> x <strong>{{ $format.price(orderProduct.price) }}</strong> {{ $lng.__('lk_webmaster_webvorkshop', 'bonuses') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrderProduct',
    props: {
        orderProduct: {type: Object}
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss">
@import '../../../../scss/utils/mixins.scss';
@import '../../../../scss/utils/variables.scss';

.orderProduct {
    width: 100%;
    padding: 20px 20px 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    &__title {
        display: block;
        padding: 0 0 10px;
        font-weight: 600;
        line-height: 130%;
        font-size: 16px;
        @extend .fix-montserrat;
        @include break(hover){
            text-decoration: underline;
            cursor: pointer;
        }
    }
    &__content {
        padding: 0 0 10px;
        @extend .fix-montserrat;
    }
    &__img {
        width: 120px;
        flex: none;
        margin-right: 15px;
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    &__description {
        width: 60%;
    }
    &__price{
        @extend .fix-montserrat;
        font-size: 15px;
        strong{
            font-weight: 600;
        }
    }
}
</style>
