<template>
    <section class="webvorkshop-checkout">

        <div
            class="checkout__empty"
            v-if="orderProducts.length === 0">
            <Card>

                <h4>{{ $lng.__('lk_webmaster_webvorkshop', 'Your cart is empty') }}</h4>

                <div class="checkout__empty__link">
                    <button
                        @click="openCatalog"
                        class="wv-btn--text wv-btn--green">
                        {{ $lng.__('lk_webmaster_webvorkshop', 'Show all products') }}
                    </button>
                </div>

            </Card>
        </div>

        <section class="webvorkshop-checkout__container">
            <div class="webvorkshop-checkout__orders-container">

                <OrderProduct
                    v-for="product in orderProducts"
                    :key="product.product_id"
                    :orderProduct="product"
                    class="webvorkshop-checkout__product">
                    <button
                        class="webvorkshop-checkout__product-delete"
                        @click="removeProduct(product)">
                        <SvgUse :width="18" :height="18" id="delete"></SvgUse>
                    </button>
                </OrderProduct>

                <div
                    class="webvorkshop-checkout__total-price"
                    v-if="orderProducts.length > 0">
                    <h4>
                        {{ $lng.__('lk_webmaster_webvorkshop', 'Order amount') }}:
                        {{ $format.price(getTotal) }}
                        {{ $lng.__('lk_webmaster_webvorkshop', 'bonuses') }}
                    </h4>
                </div>

            </div>

            <div class="webvorkshop-checkout__form" v-if="orderProducts.length > 0">

                <div class="checkout__container">
                    <template v-if="currentBonusBalance < getTotal">
                        <div class="checkout__notEnoughtBonuses">
                            <h2>{{ $lng.__('lk_webmaster_webvorkshop', 'Not enough bonuses') }}</h2>
                            <p>{{ $lng.__('lk_webmaster_webvorkshop', 'You do not have enough bonuses in your account. Change the contents of your order or get additional bonuses.') }}</p>
                            <p>{{ $lng.__('lk_webmaster_webvorkshop', 'Your current balance:') }} {{ currentBonusBalance }}</p>
                            <p>{{ $lng.__('lk_webmaster_webvorkshop', 'Need for this order:') }} {{ $format.price(getTotal) }}</p>
                        </div>
                    </template>
                    <template v-if="currentBonusBalance >= getTotal">
                        <h3 class="checkout__title">
                            {{ $lng.__('lk_webmaster_webvorkshop', 'Personal information') }}
                        </h3>

                        <div class="grid">
                            <div class="row pt-20">
                                <div class="c-sm-4 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'First name')"
                                        v-model="order.contacts.first_name"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthBiggerThan(order.contacts.first_name, 2),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Enter the First Name')
                                        },
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.contacts.first_name, 64),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 64 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                                <div class="c-sm-4 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'Last name')"
                                        v-model="order.contacts.second_name"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthBiggerThan(order.contacts.second_name, 2),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Enter the Last Name')
                                        },
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.contacts.second_name, 64),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 64 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                                <div class="c-sm-4 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'Middle name')"
                                        v-model="order.contacts.middle_name"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.contacts.middle_name, 64),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 64 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                                <div class="c-sm-6 pb-40">
                                    <FormInput
                                        label="Email"
                                        v-model="order.contacts.email"
                                        :validations="[
                                        {
                                            rule: $validations.isEmailValid(order.contacts.email),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Verify email')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                                <div class="c-sm-6 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'Phone')"
                                        v-model="order.contacts.phone"
                                        :validations="[
                                        {
                                            rule: $validations.isPhoneValid(order.contacts.phone),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Verify phone')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>

                            </div>
                        </div>

                        <h3 class="checkout__title">
                            {{ $lng.__('lk_webmaster_webvorkshop', 'What is the best way to contact you?') }}
                        </h3>

                        <div class="grid">
                            <div class="row pt-20">
                                <div class="c-sm-4 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'Telegram')"
                                        v-model="order.contacts.telegram"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.contacts.telegram, 64),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 64 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                                <div class="c-sm-4 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'Whatsapp')"
                                        v-model="order.contacts.whatsapp"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.contacts.whatsapp, 64),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 64 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                                <div class="c-sm-4 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'Skype')"
                                        v-model="order.contacts.skype"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.contacts.skype, 64),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 64 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                            </div>
                        </div>

                        <h3 class="checkout__title">
                            {{ $lng.__('lk_webmaster_webvorkshop', 'Information for delivery') }}
                        </h3>

                        <div class="grid">
                            <div class="row pt-20">
                                <div class="c-sm-12 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'Country')"
                                        v-model="order.address.country"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthBiggerThan(order.address.country, 2),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Enter the Country')
                                        },
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.address.country, 64),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 64 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                                <div class="c-sm-6 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'City')"
                                        v-model="order.address.city"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthBiggerThan(order.address.city, 2),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Enter the City')
                                        },
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.address.city, 64),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 64 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                                <div class="c-sm-6 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'ZIP code')"
                                        placeholder="000000"
                                        v-model="order.address.zipcode"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthBiggerThan(order.address.zipcode, 4),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must contain at least 4 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                            </div>
                            <div class="row">
                                <div class="c-sm-6 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'Street')"
                                        v-model="order.address.street"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthBiggerThan(order.address.street, 2),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Enter the Street')
                                        },
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.address.street, 64),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 64 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                                <div class="c-sm-3 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'House')"
                                        v-model="order.address.house"
                                        :validations="[
                                        {
                                            rule: $validations.isStringNotEmpty(order.address.house),
                                            message: 'Enter house number'
                                        },
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.address.house, 32),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 32 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                                <div class="c-sm-3 pb-40">
                                    <FormInput
                                        :label="$lng.__('lk_webmaster_webvorkshop', 'Apartment')"
                                        v-model="order.address.apartment"
                                        :validations="[
                                        {
                                            rule: $validations.isStringLengthSmallThan(order.address.apartment, 32),
                                            message: $lng.__('lk_webmaster_webvorkshop', 'Must be less than 32 symbols')
                                        }
                                    ]"
                                    ></FormInput>
                                </div>
                            </div>
                        </div>

                        <h3 class="checkout__title">
                            {{ $lng.__('lk_webmaster_webvorkshop', 'Comments') }}
                        </h3>

                        <FormTextarea
                            class="checkout__textarea"
                            label=""
                            v-model="order.contacts.comment"
                            :placeholder="$lng.__('lk_webmaster_webvorkshop', 'Write any comments for the manager')"
                        ></FormTextarea>

                        <div
                            class="checkout__agreement"
                            @click="agreement = !agreement;isShowAgreementAlert = false;">
                            <FormRadio
                                :size="20"
                                :checked="agreement"
                                v-model="agreement"
                            ></FormRadio>
                            {{ $lng.__('lk_webmaster_webvorkshop', 'I agree to the processing of my personal data and agree to the') }}
                            <a
                                target="_blank"
                                class="register__agreement__link"
                                :href="$config.baseUrl + '/terms/terms-en.pdf'">
                                {{ $lng.__('lk_webmaster_webvorkshop', 'terms and conditions') }}
                            </a>
                        </div>

                        <Alert
                            v-if="hasValidationErrors"
                            addclass="mb-30">
                            <div class="mb-5">
                                <strong>{{ $lng.__('lk_webmaster_webvorkshop', 'Please check required fields') + ':' }}</strong>
                            </div>
                            <p class="pb-5" v-for="error in validationErrors">
                            - {{ $lng.__('lk_webmaster_webvorkshop', error) }}
                        </p>
                        </Alert>

                        <button
                            v-if="!isOrderCreatingInProcess"
                            @click="createOrder()"
                            class="checkout__button wv-btn--green-active wv-btn--text">
                            {{ $lng.__('lk_webmaster_webvorkshop', 'Create order') }}
                        </button>
                        <button
                            v-if="isOrderCreatingInProcess"
                            class="checkout__button wv-btn--gray-active wv-btn--text">
                            {{ $lng.__('lk_webmaster_webvorkshop', 'Saving the order') }}..
                        </button>
                    </template>
                </div>

            </div>
        </section>
    </section>
</template>

<script>
import OrderProduct from '@/views/Webmaster/Webvorkshop/Orders/OrderProduct';

export default {
    name: 'Checkout',
    components: {
        OrderProduct,
    },
    data() {
        return {
            agreement: false,
            validationErrors: [],
            isOrderCreatingInProcess: false,
            optionsGeo: [
                {
                    id: 4,
                    name: 'Italy',
                },
            ],
        };
    },
    watch: {
        order: {
            handler(order) {
                this.validationErrors = [];
                this.$store.dispatch('shop/saveOrderToLocalStorage', order);
            },
            deep: true,
        }
    },
    methods: {
        removeProduct(product) {
            this.$store.commit('shop/removeProductFromCart', product.product_id);
        },
        openCatalog() {
            this.$router.push('/' + this.$route.params.lng + '/webmaster/shop');
        },
        createOrder() {
            this.checkValidationErrors();
            if (!this.hasValidationErrors && this.agreement) {
                this.isOrderCreatingInProcess = true;
                this.$store.dispatch('shop/checkout')
                    .then(() => {
                        this.$store.commit('shop/clearOrderProducts');
                        this.$router.push('/' + this.$route.params.lng + '/webmaster/shop/orders');
                    });
            }
        },
        checkValidationErrors() {
            WV.Bus.emit('fireValidations');
            this.validationErrors = [];

            // First Name
            if (!this.$validations.isStringLengthBiggerThan(this.order.contacts.first_name, 2)) {
                this.validationErrors.push('Enter the First Name');
            }
            if (!this.$validations.isStringLengthSmallThan(this.order.contacts.first_name, 64)) {
                this.validationErrors.push('First Name must be less than 64 symbols');
            }

            // Last Name
            if (!this.$validations.isStringLengthBiggerThan(this.order.contacts.second_name, 2)) {
                this.validationErrors.push('Enter the Last Name');
            }
            if (!this.$validations.isStringLengthSmallThan(this.order.contacts.second_name, 64)) {
                this.validationErrors.push('Last Name must be less than 64 symbols');
            }

            // Middle Name
            if (!this.$validations.isStringLengthSmallThan(this.order.contacts.middle_name, 64)) {
                this.validationErrors.push('Middle Name must be less than 64 symbols');
            }

            // Email
            if (!this.$validations.isEmailValid(this.order.contacts.email)) {
                this.validationErrors.push('Email is invalid');
            }

            // Phone
            if (!this.$validations.isPhoneValid(this.order.contacts.phone)) {
                this.validationErrors.push('Phone is invalid');
            }

            // Telegram
            if (!this.$validations.isStringLengthSmallThan(this.order.contacts.telegram, 64)) {
                this.validationErrors.push('Telegram account must be less than 64 symbols');
            }

            // Whatsap
            if (!this.$validations.isStringLengthSmallThan(this.order.contacts.whatsapp, 64)) {
                this.validationErrors.push('Whatsapp account must be less than 64 symbols');
            }

            // Skype
            if (!this.$validations.isStringLengthSmallThan(this.order.contacts.skype, 64)) {
                this.validationErrors.push('Skype account must be less than 64 symbols');
            }

            // Country
            if (!this.$validations.isStringLengthBiggerThan(this.order.address.country, 2)) {
                this.validationErrors.push('Enter the Country');
            }
            if (!this.$validations.isStringLengthSmallThan(this.order.address.country, 64)) {
                this.validationErrors.push('Country name must be less than 64 symbols');
            }

            // City
            if (!this.$validations.isStringLengthBiggerThan(this.order.address.city, 2)) {
                this.validationErrors.push('Enter the City');
            }
            if (!this.$validations.isStringLengthSmallThan(this.order.address.city, 64)) {
                this.validationErrors.push('City must be less than 64 symbols');
            }

            // ZIP code
            if (!this.$validations.isStringLengthBiggerThan(this.order.address.zipcode, 4)) {
                this.validationErrors.push('Enter the correct ZIP code');
            }

            // Street
            if (!this.$validations.isStringLengthBiggerThan(this.order.address.street, 2)) {
                this.validationErrors.push('Enter the Street name');
            }
            if (!this.$validations.isStringLengthSmallThan(this.order.address.street, 64)) {
                this.validationErrors.push('Street must be less than 64 symbols');
            }

            // House
            if (!this.$validations.isStringNotEmpty(this.order.address.house)) {
                this.validationErrors.push('Enter the House number');
            }
            if (!this.$validations.isStringLengthSmallThan(this.order.address.house, 32)) {
                this.validationErrors.push('House number must be less than 32 symbols');
            }

            // Apartment
            if (!this.$validations.isStringLengthSmallThan(this.order.address.apartment, 32)) {
                this.validationErrors.push('Apartment number must be less than 32 symbols');
            }

            // Agreement
            if (!this.agreement) {
                this.validationErrors.push('You need agree with terms and conditions');
            }

        },
    },
    computed: {
        order: {
            get() {
                return this.$store.state.shop.order;
            },
            set(order) {
                this.$store.commit('shop/set', ['order', order]);
            },
        },
        orderProducts() {
            return this.order.products;
        },
        hasValidationErrors() {
            return this.validationErrors.length > 0;
        },
        getTotal() {
            if (this.orderProducts.length > 0) {
                let total = 0;
                this.orderProducts.forEach((product) => {
                    total += (product.quantity * product.price);
                });
                return total;
            }
        },
        currentBonusBalance() {
            return this.$store.state.webmaster.statMini.bonus || 0;
        },

    },
};
</script>

<style lang="scss" scoped>
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.webvorkshop-checkout {
    width: 100%;
    margin-top: 90px;
    @include break(md) {
        margin-top: 30px;
    }
    &__container {
        display: grid;
        grid-template-columns: 100%;
        gap: 20px;
        @include break(lg) {
            grid-template-columns: 45% 55%;
        }
    }
    &__form {
        margin-right: -20px;
        @include break(lg) {
            margin-right: -10px;
        }
    }
    &__orders-container {
        width: 100%;
        grid-column: 1;
        grid-row: 1;
    }
    &__product-delete {
        position: absolute;
        top: 15px;
        right: 0;
        padding: 20px;
        cursor: pointer;
        border: none;
        background: transparent;
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
        @include maxWidth(680) {
            padding: 10px;
        }
    }
    &__product {
        position: relative;
        width: 100%;
        border-bottom: 2px solid $color-day-line;
    }
    &__total-price {
        padding: 40px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
            padding: 0 0 0 20px;
        }
    }
}
.checkout {
    width: 100%;
    &__title {
        line-height: 26px;
        padding-bottom: 20px;
    }
    &__empty {
        width: 100%;
        text-align: center;
        &__link {
            margin-right: -20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 30px;
            @extend .fix-montserrat;
        }
    }
    &__container {
        border-radius: 20px 0 0 20px;
        padding: 40px 20px;
        width: 100%;
        background: white;
    }
    &__button {
        display: block;
    }
    &__agreement {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-family: $font-secondary;
        font-size: 12px;
        margin: 20px auto;
        width: 100%;
        & > div:first-child {
            margin-right: 10px;
        }
        a {
            text-decoration: underline;
            padding-left: 4px;
        }
    }
    &__notEnoughtBonuses{
        padding-left: 20px;
        p{
            font-family: $font-primary;
            @extend .fix-montserrat;
            padding-top: 15px;
            line-height: 140%;
            font-size: 17px;
        }
    }
}
</style>
