<template>
    <div class="CatalogFilters">
        <Card>
            <div class="CatalogFilters__wrapper">

                <FormSelect
                    :label="$lng.__('lk_webmaster_webvorkshop', 'Category')"
                    :placeholder="$lng.__('lk_webmaster_webvorkshop', 'Filter by category')"
                    :options="taxons"
                    v-model:selected="filters.byCategory"
                ></FormSelect>

                <FormSelect
                    :label="$lng.__('lk_webmaster_webvorkshop', 'Sort by')"
                    :showResetOption=false
                    :options="sortByOptionsTranslated"
                    v-model:selected="sortBy"
                ></FormSelect>

            </div>
        </Card>
    </div>
</template>

<script>
export default {
    name: 'CatalogFilters',
    props: [],
    data() {
        return {
            filters: {},
        };
    },
    watch: {
        filters: {
            deep: true,
            handler: (filters, oldFilters) => {
                this.$store.commit('shop/set', ['catalogFilters', filters]);
            }
        }
    },
    computed: {
        sortByOptionsTranslated() {
            return collect(this.$store.state.shop.sortByOptions)
                .map(option => {
                    option.name = this.$lng.__('lk_webmaster_webvorkshop', option.name);
                    return option;
                })
                .all()
        },
        taxons() {
            return this.$store.state.shop.taxons;
        },
        sortBy: {
            get() {
                return this.$store.state.shop.sortBy;
            },
            set(val, oldVal) {
                this.$store.commit('shop/set', ['sortBy', val]);
            },
        },
    },
    methods: {},
    beforeMount() {
        this.filters = this.$store.state.shop.catalogFilters;
    }
};
</script>

<style lang="scss">
@import '../../../../scss/utils/mixins.scss';
@import '../../../../scss/utils/variables.scss';

.CatalogFilters {
    width: 100%;
    margin-bottom: 15px;
    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 -10px 0;
        & > * {
            margin: 20px 10px 0;
        }
    }
}

</style>
