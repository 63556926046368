<template>
    <div class="CatalogGrid">
        <CatalogGridCard
            v-for="product in productsLimited"
            :key="product.id"
            :product="product"
        ></CatalogGridCard>
        <div class="CatalogGrid__more">
            <button
                v-if="productsVisible < productsFiltered.length"
                @click="productsVisible+=15"
                class="wv-btn--text wv-btn--green-active">
                {{ $lng.__('lk_webmaster_webvorkshop', 'Show more') }}
            </button>
        </div>
    </div>
</template>

<script>
import CatalogGridCard from './CatalogGridCard.vue';

export default {
    name: 'CatalogGrid',
    components: {
        CatalogGridCard,
    },
    props: [],
    data() {
        return {
            productsVisible: 15,
        };
    },
    computed: {
        products() {
            return collect(this.$store.state.shop.products)
                .where('is_inactive', false)
                .all();
        },
        filters() {
            return this.$store.state.shop.catalogFilters;
        },
        productsFiltered() {
            return collect(this.products)
                .filter(product => {
                    let pass = true;

                    if (this.filters.byCategory) {
                        let taxons = product.taxons === null ? [] : product.taxons;
                        pass = taxons.indexOf(this.filters.byCategory) >= 0;
                    }
                    return pass;
                })
                .all();
        },
        productsSorted() {
            let sortBy = this.$store.state.shop.sortBy;

            if (sortBy === 'avail') {
                return collect(this.productsFiltered)
                    .sortBy('is_inactive')
                    .all()
            }

            if (sortBy === 'pricelh') {
                return collect(this.productsFiltered)
                    .sortBy('price')
                    .all()
            }

            if (sortBy === 'pricehl') {
                return collect(this.productsFiltered)
                    .sortByDesc('price')
                    .all()
            }
        },
        productsLimited() {
            return collect(this.productsSorted)
                .take(this.productsVisible)
                .all()
        },
    },
    methods: {},
    beforeMount() {
    },
    mounted() {
        WV.components.webvorkshop_cataloggrid = this;
    },
};
</script>

<style lang="scss">
@import '../../../../scss/utils/mixins.scss';
@import '../../../../scss/utils/variables.scss';

.CatalogGrid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;
    &__more{
        padding-top: 30px;
        width: 100%;
        flex:none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
