<template>
    <div class="Orders">

        <Loading
            v-if="!dataLoaded"
        ></Loading>

        <div
            class="Orders__empty"
            v-if="orders.length === 0 && dataLoaded">
            <Card>

                <div class="Orders__empty__title">
                    <h3>{{ $lng.__('lk_webmaster_webvorkshop', 'No orders placed') }}</h3>
                </div>

                <div class="Orders__empty__link">
                    <button
                        @click="openCatalog"
                        class="wv-btn--text wv-btn--green">
                        {{ $lng.__('lk_webmaster_webvorkshop', 'Show all products') }}
                    </button>
                </div>

            </Card>
        </div>

        <section
            v-if="dataLoaded"
            class="webvorkshop-orders__container">
            <Order
                v-for="order in orders"
                :key="order.id"
                :order="order"
            ></Order>
        </section>
    </div>
</template>

<script>
import Order from '@/views/Webmaster/Webvorkshop/Orders/Order.vue';

export default {
    name: 'Orders',
    components: {
        Order,
    },
    data() {
        return {
            dataLoaded: false,
        };
    },
    computed: {
        orders() {
            return collect(this.$store.state.shop.orders)
                .sortByDesc('id')
                .all();
        },
    },
    methods: {
        openCatalog() {
            this.$router.push('/' + this.$route.params.lng + '/webmaster/shop');
        },
    },
    beforeMount() {
        Promise.all([
            this.$store.dispatch('shop/getOrders')
        ]).then(() => {
            this.dataLoaded = true;
        })

    }
};
</script>

<style lang="scss">

    @import '../../../../scss/utils/mixins.scss';
    @import '../../../../scss/utils/variables.scss';

    .Orders {
        width: 100%;
        margin-top: 90px;
        @include break(md) {
            margin-top: 0;
        }
        &__container {
            padding: 40px 0;
        }
        &__empty {
            width: 100%;
            text-align: center;
            .card{
                padding-top: 50px;
                padding-bottom: 50px;
            }
            &__title{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &__link {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 30px;
            }
        }
    }
</style>
