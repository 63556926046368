<template>
    <div
        class="CatalogGridCard"
        @click.prevent="$router.push('/'+$route.params.lng+'/webmaster/shop/product/'+product.id)"
        :class="{'CatalogGridCard__inactive': product.is_inactive}">
        <div class="CatalogGridCard__wrapper">
            <div class="CatalogGridCard__name">
                {{ product.name.length > 40 ? product.name.slice(0, 40)+'...' : product.name }}
            </div>
            <div class="CatalogGridCard__price">
                {{ $format.price(product.price) }}
            </div>
            <div class="CatalogGridCard__currency">
                {{ $lng.__('lk_webmaster_webvorkshop', 'bonuses') }}
            </div>
            <div class="CatalogGridCard__gallery">

                    <Swiper
                        :slidesPerView="1"
                        :spaceBetween="0"
                        :loop="true"
                        :grabCursor="productImages.length > 1"
                        :autoHeight="true"
                        :pagination="{ clickable: true }"
                    >
                        <SwiperSlide v-for="image in productImages">
                            <img
                                :src="image"
                                :alt="product.name"
                            >
                        </SwiperSlide>
                    </Swiper>

            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, {Pagination} from 'swiper/core';
SwiperCore.use([Pagination]);

export default {
    name: 'CatalogGridCard',
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        product: {
            type: Object,
        }
    },
    data() {
        return {};
    },
    computed: {
        productImages() {
            return this.product.images === null
                ? ['https://minio.cdnwv.com/lk.webvork/goods/placeholders/sm.png']
                : this.product.images;
        },
    },
    methods: {},
    beforeMount() {
    }
};
</script>

<style lang="scss">
@import '../../../../scss/utils/mixins.scss';
@import '../../../../scss/utils/variables.scss';

.CatalogGridCard{
    background: white;
    margin: 10px;
    width: calc(100% - 20px);
    cursor: pointer;
    border-radius: 10px;
    transition: background .3s, box-shadow .3s;
    @include break(hover){
        box-shadow: var(--card-hover-shadow);
    }
    @include break(sm) {
        width: calc(50% - 20px);
    }
    @include break(lg) {
        width: calc(33.333% - 20px);
    }
    @include break(xl) {
        width: calc(25% - 20px);
    }
    @include break(xxl) {
        width: calc(20% - 20px);
    }
    &__inactive{
        opacity: .7;
    }
    &__wrapper{
        height: 400px;
        padding: 20px;
        font-family: $font-primary;
        @extend .fix-montserrat;
        position: relative;
    }
    &__name{
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        height: 70px;
    }
    &__price{
        font-weight: 700;
        font-size: 24px;
    }
    &__currency{
        padding-top: 10px;
        color: $color-text-muted;
        font-size: 14px;
    }
    &__gallery{
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
        top: auto;
        .swiper-container{
            padding-bottom: 20px !important;
        }
        .swiper-wrapper{
            position: relative;
        }
        .swiper-slide{
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .swiper-pagination{
            bottom: 0;
        }
    }
}
</style>
